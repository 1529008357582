import axios from "../../axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  //
  USER_ADD_SUCCESS,
  USER_ADD_REQUEST,
  USER_ADD_FAIL,
  //
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_FAIL,
  //
  USER_PROFILE_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_FAIL,
  //
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_FAIL,
  //
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_FAIL,
  //
  USER_DELETE_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_FAIL,
  //
  COORDINATOR_LIST_SUCCESS,
  COORDINATOR_LIST_REQUEST,
  COORDINATOR_LIST_FAIL,
  //
  COORDINATOR_ADD_SUCCESS,
  COORDINATOR_ADD_REQUEST,
  COORDINATOR_ADD_FAIL,
  //
  COORDINATOR_DETAIL_SUCCESS,
  COORDINATOR_DETAIL_REQUEST,
  COORDINATOR_DETAIL_FAIL,
  //
  COORDINATOR_UPDATE_SUCCESS,
  COORDINATOR_UPDATE_REQUEST,
  COORDINATOR_UPDATE_FAIL,
  //
  USER_UPDATE_LOGIN_SUCCESS,
  USER_UPDATE_LOGIN_REQUEST,
  USER_UPDATE_LOGIN_FAIL,
  //
  USER_HISTORY_LOGED_SUCCESS,
  USER_HISTORY_LOGED_REQUEST,
  USER_HISTORY_LOGED_FAIL,
  //
  USER_HISTORY_SUCCESS,
  USER_HISTORY_REQUEST,
  USER_HISTORY_FAIL,

  //
  USER_LOGOUT_SAVE_SUCCESS,
  USER_LOGOUT_SAVE_REQUEST,
  USER_LOGOUT_SAVE_FAIL,
  //
} from "../constants/userConstants";
import { UAParser } from "ua-parser-js";

export const getHistoryListCoordinator =
  (page, coordinator) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_HISTORY_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.get(
        `/users/get-history-coordinator/${coordinator}/?page=${page}`,
        config
      );

      dispatch({
        type: USER_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: USER_HISTORY_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

export const getHistoryListLogged = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_HISTORY_LOGED_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/users/get-history-byloged/?page=${page}`,
      config
    );

    dispatch({
      type: USER_HISTORY_LOGED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_HISTORY_LOGED_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const updateLastLogin = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_LOGIN_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/users/update-login-time/`, {}, config);

    dispatch({
      type: USER_UPDATE_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_UPDATE_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const updateCoordinator =
  (id, coordinator) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COORDINATOR_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.put(
        `/users/coordinator-update/${id}/`,
        coordinator,
        config
      );

      dispatch({
        type: COORDINATOR_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: COORDINATOR_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

export const getCoordinatorDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COORDINATOR_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/users/coordinator/` + id, config);

    dispatch({
      type: COORDINATOR_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: COORDINATOR_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const updateUserPassword = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PASSWORD_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/users/update-password/`, user, config);

    dispatch({
      type: USER_PASSWORD_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_PASSWORD_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "Votre profile n'a pas été modifié, réessayez",
    });
  }
};

export const createNewCoordinator =
  (coordinator) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COORDINATOR_ADD_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/users/create-coordinator/`,
        coordinator,
        config
      );

      dispatch({
        type: COORDINATOR_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      var err =
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail;
      if (err) {
        if (err === "Given token not valid for any token type") {
          localStorage.removeItem("userInfoUnimedCare");
          document.location.href = "/";
        }
      }
      dispatch({
        type: COORDINATOR_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : "This Coordinator has not been added, please try again.",
      });
    }
  };

export const getListCoordinators = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COORDINATOR_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(
      `/users/coordinators/?page=${page}`,
      config
    );

    dispatch({
      type: COORDINATOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: COORDINATOR_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.delete(`/users/delete/${id}/`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "Votre profile n'a pas été modifié, réessayez",
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PROFILE_UPDATE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.put(`/users/update-profile/`, user, config);

    dispatch({
      type: USER_PROFILE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_PROFILE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "Votre profile n'a pas été modifié, réessayez",
    });
  }
};

export const getUserProfile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/users/profile/`, config);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const addNewUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ADD_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(`/users/add/`, user, config);

    dispatch({
      type: USER_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "This user has not been added, please try again.",
    });
  }
};

export const getListUsers = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/users/?page=${page}`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const login = (username, password) => async (dispatch) => {
  try {
    const parser = new UAParser();
    const result = parser.getResult();

    const browser = result.browser.name || "Unknown browser";
    let device = "";
    if (result.device.vendor) {
      device = result.device.vendor + " - ";
    }
    device += result.device.model || result.device.type || "Unknown device";

    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/users/login/",
      {
        username: username,
        password: password,
        device: device,
        browser: browser,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfoUnimedCare", JSON.stringify(data));
  } catch (error) {
    console.log(error);

    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfoUnimedCare");
  dispatch({ type: USER_LOGOUT });
  document.location.href = "/";
};

export const LogoutSaved = () => async (dispatch, getState) => {
  try {
    const parser = new UAParser();
    const result = parser.getResult();

    const browser = result.browser.name || "Unknown browser";
    let device = "";
    if (result.device.vendor) {
      device = result.device.vendor + " - ";
    }
    device += result.device.model || result.device.type || "Unknown device";

    dispatch({
      type: USER_LOGOUT_SAVE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.post(
      `/users/logout-saved/`,
      { device: device, browser: browser },
      config
    );

    dispatch({
      type: USER_LOGOUT_SAVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    var err =
      error.response && error.response.data.detail
        ? error.response.data.detail
        : error.detail;
    if (err) {
      if (err === "Given token not valid for any token type") {
        localStorage.removeItem("userInfoUnimedCare");
        document.location.href = "/";
      }
    }
    dispatch({
      type: USER_LOGOUT_SAVE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : "This user has not been added, please try again.",
    });
  }
};
