import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getHistoryListLogged,
  getUserProfile,
  updateUserPassword,
  updateUserProfile,
} from "../../redux/actions/userActions";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";
import { baseURLFile } from "../../constants";
import userLoginIcon from "../../images/icon/bx-user-check.png";
import { casesListLogged } from "../../redux/actions/caseActions";
import Paginate from "../../components/Paginate";

function ProfileScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const page = searchParams.get("page") || "1";

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const profileUser = useSelector((state) => state.getProfileUser);
  const {
    loadingUserProfile,
    userProfile,
    successUserProfile,
    errorUserProfile,
  } = profileUser;

  const listCases = useSelector((state) => state.caseListLogged);
  const { casesLogged, loadingCasesLogged, errorCasesLogged, pages } =
    listCases;

  const listHistory = useSelector((state) => state.historyListLogged);
  const { historyLogged, loadingHistoryLogged, errorHistoryLogged } =
    listHistory;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getUserProfile());
      dispatch(getHistoryListLogged("0"));
      dispatch(casesListLogged(page));
    }
  }, [navigate, userInfo, dispatch, page]);

  const caseStatus = (casestatus) => {
    switch (casestatus) {
      case "pending-coordination":
        return "Pending Coordination";
      case "coordinated-missing-m-r":
        return "Coordinated, Missing M.R.";
      case "coordinated-missing-invoice":
        return "Coordinated, Missing Invoice";
      case "waiting-for-insurance-authorization":
        return "Waiting for Insurance Authorization";
      case "coordinated-patient-not-seen-yet":
        return "Coordinated, Patient not seen yet";
      case "fully-coordinate":
        return "Fully Coordinated";
      default:
        return casestatus;
    }
  };

  const formatDate = (dateString) => {
    if (dateString && dateString !== "") {
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  function formatDateTime(dateString) {
    const date = new Date(dateString);
    return date
      .toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
      .replace(" , ", " at"); // Adjust formatting to include "at"
  }

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Profile</div>
        </div>
        {/*  */}
        {loadingUserProfile ? (
          <Loader />
        ) : errorUserProfile ? (
          <Alert type={"error"} message={errorUserProfile} />
        ) : userProfile ? (
          <>
            <div className="my-5 text-[#303030] text-opacity-60">
              {/* profile */}
              <div className="bg-white rounded shadow-1 my-1 w-full px-3 py-4 flex flex-row items-end text-xs">
                <div>
                  {userProfile.photo ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={baseURLFile + userProfile.photo}
                    >
                      <img
                        className="size-20 rounded-2xl shadow-1"
                        alt={userProfile.full_name}
                        src={baseURLFile + userProfile.photo}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/placeholder.png";
                        }}
                      />
                    </a>
                  ) : (
                    <img
                      className="size-20 rounded-2xl shadow-1"
                      alt={userProfile.full_name}
                      src={"/assets/placeholder.png"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/placeholder.png";
                      }}
                    />
                  )}
                </div>
                <div className="flex-1 px-5">
                  <div className="text-sm">{userProfile.full_name}</div>
                  <div className="flex md:flex-row flex-col md:items-center">
                    <div className="flex-1 px-2 flex flex-row items-center my-1">
                      <div className="flex flex-row items-center ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5 text-[#32475C] text-opacity-55 mx-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                          />
                        </svg>

                        <div className="mx-1">
                          Joined {formatDate(userProfile.created_at)}
                        </div>
                      </div>
                    </div>
                    <div className="mx-2 bg-[#0388A6] px-5 py-2 rounded-md flex flex-row items-center text-white text-sm w-max  ">
                      <img
                        alt="loginuser"
                        className="size-5 mx-1"
                        src={userLoginIcon}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/assets/placeholder.png";
                        }}
                      />
                      <div className="mx-1 font-medium ">
                        {" "}
                        {userProfile.is_online ? "Connected" : "Not Connected"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="flex md:flex-row flex-col">
                <div className="md:w-1/3 w-full px-3">
                  <div className="bg-white rounded shadow-1 my-1 w-full px-3 py-4">
                    <div className="my-3 text-xs">ABOUT</div>
                    <div className=" my-4">
                      <div className="flex flex-row items-center  my-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>

                        <div className="flex-1 text-sm px-1">
                          <strong className="font-semibold">Full Name: </strong>
                          {userProfile.full_name}
                        </div>
                      </div>
                      {/*  */}
                      <div className="flex flex-row items-center my-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m4.5 12.75 6 6 9-13.5"
                          />
                        </svg>

                        <div className="flex-1 text-sm px-1">
                          <strong className="font-semibold">Status: </strong>
                          {userProfile.is_active ? "Active" : "No Active"}
                        </div>
                      </div>
                      {/*  */}
                      <div className="flex flex-row items-center my-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                          />
                        </svg>

                        <div className="flex-1 text-sm px-1">
                          <strong className="font-semibold">Role: </strong>
                          Coordinator
                        </div>
                      </div>
                    </div>
                    <div className="my-3 text-xs">CONTACTS</div>
                    <div className=" my-4">
                      <div className="flex flex-row items-center  my-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>

                        <div className="flex-1 text-sm px-1">
                          <strong className="font-semibold">Contacts: </strong>
                          {userProfile.phone}
                        </div>
                      </div>
                      {/*  */}
                      <div className="flex flex-row items-center  my-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                          />
                        </svg>

                        <div className="flex-1 text-sm px-1">
                          <strong className="font-semibold">Email: </strong>
                          {userProfile.email}
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
                <div className="md:w-2/3 w-full px-3">
                  <div className="bg-white rounded shadow-1 my-1 w-full px-3 py-4">
                    <div className="my-3 text-xs">Login/logout history</div>
                    <div className=" my-4 max-h-[11.5rem] overflow-auto">
                      {loadingHistoryLogged ? (
                        <div>Loading...</div>
                      ) : errorHistoryLogged ? (
                        <Alert type={"error"} message={errorHistoryLogged} />
                      ) : historyLogged ? (
                        <div>
                          {historyLogged?.map((item, index) => (
                            <div className="flex flex-row item-center my-3 ">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-4"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 text-xs mx-1 ">
                                <div className="font-bold">
                                  {formatDateTime(item.created_at)}
                                </div>
                                <div className="my-1 mx-1">
                                  {item.type_history === "Login"
                                    ? (item.device ?? "") +
                                      " | " +
                                      (item.browser ?? "")
                                    : "You are logged out at this time."}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className=" w-full  px-1 py-3 ">
                <div className="py-4 px-2 shadow-1 bg-white">
                  {loadingCasesLogged ? (
                    <Loader />
                  ) : errorCasesLogged ? (
                    <Alert type="error" message={errorCasesLogged} />
                  ) : (
                    <div className="max-w-full overflow-x-auto ">
                      <table className="w-full table-auto">
                        <thead>
                          <tr className=" bg-[#F3F5FB] text-left ">
                            <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                              ID
                            </th>
                            <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                              Client
                            </th>
                            <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                              Patient Name
                            </th>
                            <th className="min-w-[30px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Type
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Assigned Provider
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Status
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                              Date Created
                            </th>
                            <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max"></th>
                          </tr>
                        </thead>
                        {/*  */}
                        <tbody>
                          {casesLogged?.map((item, index) => (
                            //  <a href={`/cases/detail/${item.id}`}></a>
                            <tr key={index}>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  #{item.id}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.assurance?.assurance_name ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.patient?.full_name ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.case_type ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {item.provider?.full_name ?? "---"}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {caseStatus(item.status_coordination)}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max  ">
                                  {formatDate(item.case_date)}
                                </p>
                              </td>
                              <td className=" py-3 px-4 min-w-[120px]  ">
                                <p className="text-black  text-xs w-max flex flex-row  ">
                                  <Link
                                    className="mx-1 detail-class"
                                    to={"/cases-list/detail/" + item.id}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 bg-warning rounded p-1 text-white text-center text-xs"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                                      />
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                      />
                                    </svg>
                                  </Link>
                                </p>
                              </td>
                            </tr>
                          ))}
                          <tr className="h-5"></tr>
                        </tbody>
                      </table>
                      <div className="">
                        <Paginate
                          route={`/profile/?`}
                          search={""}
                          page={page}
                          pages={pages}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*  */}
            </div>
          </>
        ) : null}
      </div>
    </DefaultLayout>
  );
}

export default ProfileScreen;
