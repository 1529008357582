import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { login } from "../../redux/actions/userActions";
import Alert from "../../components/Alert";
import { useNavigate } from "react-router-dom";

import bgLogin from "../../images/bg-login.png";
import logoProjet from "../../images/logo-project.png";
import imgLogin from "../../images/image-login.png";

function LoginScreen() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [isCheck, setIsCheck] = useState(false);

  // const redirect = '/dashboard'

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [navigate, userInfo]);

  const submitHandle = async (e) => {
    console.log("jj");

    e.preventDefault();
    dispatch(login(username, password));
  };

  // return (
  //   <div>
  //     <div className="h-screen w-screen">
  //       <iframe
  //         title="Om Nom Run Game"
  //         src="https://play.famobi.com/wrapper/om-nom-run/A1000-10"
  //         className="w-full h-full"
  //         frameBorder="0"
  //         scrolling="no"
  //         allowFullScreen
  //       ></iframe>
  //     </div>
  //   </div>
  // );

  return (
    <div className="w-screen min-h-screen flex md:flex-row flex-col bg-white">
      <div
        className="md:w-1/2 w-full  bg-cover bg-no-repeat"
        style={{ backgroundImage: "url(" + bgLogin + ")" }}
      >
        <div className=" flex flex-col items-left justify-center px-12 py-5  md:h-screen">
          <img src={logoProjet} className="size-20" />
          <div className="text-[#025163] text-3xl my-5">
            Access your healthcare management tools easily and securely
          </div>
          <img src={imgLogin} className=" md:w-[70%] w-max md:block hidden" />
        </div>
      </div>
      {/*  */}
      <div className="md:w-1/2 w-full ">
        <form className=" w-full h-full flex flex-col" onSubmit={submitHandle}>
          <div className="md:w-[80%] w-full mx-auto flex flex-col items-left  px-12 py-16 md:flex-1 ">
            <div className="text-[#303030] font-bold text-2xl">Log in</div>
            {error && <Alert type="error" message={error} />}

            {loading && <h2 className="text-2xl mb-6">{loading} loading</h2>}
            <div className="mt-20 text-sm">Adresse e-mail</div>
            <input
              className=" border border-[#666666] text-sm mt-2 rounded-full  w-full py-3 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
              id="username"
              required
              type="email"
              placeholder="Adresse e-mail"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="mt-3 text-sm">Password</div>
            <div className=" border border-[#666666] mt-2 rounded-full  w-full  py-3 px-3 flex flex-row items-center">
              <input
                className=" text-gray-700 text-sm focus:outline-none focus:shadow-outline w-full flex-1"
                id="password"
                required
                type={!showPass ? "password" : "text"}
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                onClick={() => setShowPass(!showPass)}
                className=" cursor-pointer "
              >
                {showPass ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-5 text-[#666666]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-5  text-[#666666]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div className="mt-4 flex flex-row justify-between items-center">
              <div className="flex flex-row items-center">
                <input
                  checked={isCheck}
                  onChange={(v) => {
                    setIsCheck(!isCheck);
                  }}
                  id="check-save"
                  type={"checkbox"}
                />
                <label
                  for="check-save"
                  className="mx-2 text-sm text-[#030229] cursor-pointer"
                >
                  Remember me
                </label>
              </div>
              <a className=" text-sm text-[#0388A6]" href="/">
                Reset Password?
              </a>
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="bg-[#0388A6] text-white w-full rounded-full py-4 px-3 text-center"
              >
                Log in
              </button>
            </div>
          </div>
          <div className="mt-4 my-2 text-[#878787] text-center">
            Copyright © 2024 Atlas Assistance |{" "}
            <a href="" className="font-bold">
              Privacy Policy
            </a>
          </div>
        </form>
      </div>
    </div>
    // <div className="w-screen h-screen bg-cover bg-center bg-no-repeat bg-opacity-25 ">
    //   <div className="flex justify-center items-center h-screen">
    //     <form
    //       className="bg-white shadow-lg rounded mx-3 px-8 pt-6 pb-8 mb-4 md:w-1/3 w-screen"
    //       onSubmit={submitHandle}
    //     >
    //       <h2 className="text-2xl mb-6">Connectez-vous à l'administrateur</h2>
    //       {error && <Alert type="error" message={error} />}

    //       {loading && <h2 className="text-2xl mb-6">{loading} loading</h2>}
    //       <div className="mb-4">
    //         <label
    //           className="block text-gray-700 text-sm font-bold mb-2"
    //           htmlFor="username"
    //         >
    //           Adresse e-mail
    //         </label>
    //         <input
    //           className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    //           id="username"
    //           type="text"
    //           placeholder=""
    //           value={username}
    //           onChange={(e) => setUsername(e.target.value)}
    //         />
    //       </div>
    //       <div className="mb-6">
    //         <label
    //           className="block text-gray-700 text-sm font-bold mb-2"
    //           htmlFor="password"
    //         >
    //           Mot de passe
    //         </label>
    //         <div className="flex flex-row items-center">
    //           <input
    //             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
    //             id="password"
    //             type={!showPass ? "password" : "text"}
    //             placeholder=""
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //           />
    //           <div
    //             onClick={() => setShowPass(!showPass)}
    //             className=" cursor-pointer py-2 px-2 "
    //           >
    //             {showPass ? (
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke-width="1.5"
    //                 stroke="currentColor"
    //                 className="w-6 h-6"
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
    //                 />
    //               </svg>
    //             ) : (
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke-width="1.5"
    //                 stroke="currentColor"
    //                 className="w-6 h-6"
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
    //                 />
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    //                 />
    //               </svg>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //       <div className="flex md:flex-row flex-col items-center justify-between">
    //         <button
    //           className="border border-primary bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    //           type="submit"
    //         >
    //           Connexion
    //         </button>
    //         <a
    //           className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
    //           href="#!"
    //         >
    //           Mot de passe oublié?
    //         </a>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
}

export default LoginScreen;
