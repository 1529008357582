import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  deleteProvider,
  providersList,
} from "../../redux/actions/providerActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import ConfirmationModal from "../../components/ConfirmationModal";
import Select from "react-select";
import { COUNTRIES, SERVICETYPE } from "../../constants";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

function ProvidersMapScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [isMaps, setIsMaps] = useState(false);

  const [providerMapSelect, setProviderMapSelect] = useState(null);
  const [isOpenMap, setIsOpenMap] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  const [providerId, setProviderId] = useState("");

  const [searchName, setSearchName] = useState(
    searchParams.get("searchname") || ""
  );
  const [searchCity, setSearchCity] = useState(
    searchParams.get("searchcity") || ""
  );
  const [searchType, setSearchType] = useState(
    searchParams.get("searchtype") || ""
  );
  const [searchCountrySelect, setSearchCountrySelect] = useState("");
  const [searchCountry, setSearchCountry] = useState(
    searchParams.get("searchcountry") || ""
  );

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listProviders = useSelector((state) => state.providerList);
  const { providers, loadingProviders, errorProviders, pages } = listProviders;

  const providerDelete = useSelector((state) => state.deleteProvider);
  const { loadingProviderDelete, errorProviderDelete, successProviderDelete } =
    providerDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(
        providersList("0", searchName, searchType, searchCity, searchCountry)
      );
    }
  }, [
    navigate,
    userInfo,
    dispatch,
    searchName,
    searchType,
    searchCity,
    searchCountry,
  ]);

  useEffect(() => {
    if (successProviderDelete) {
      dispatch(
        providersList("0", searchName, searchType, searchCity, searchCountry)
      );
      setIsOpenMap(false);
      setProviderMapSelect(null);
    }
  }, [
    successProviderDelete,
    searchName,
    searchType,
    searchCity,
    searchCountry,
  ]);

  useEffect(() => {
    const params = new URLSearchParams();

    if (searchName) {
      params.set("searchname", searchName);
    } else {
      params.delete("searchname");
    }

    if (searchType) {
      params.set("searchtype", searchType);
    } else {
      params.delete("searchtype");
    }

    if (searchCity) {
      params.set("searchcity", searchCity);
    } else {
      params.delete("searchcity");
    }

    if (searchCountry) {
      params.set("searchcountry", searchCountry);
    } else {
      params.delete("searchcountry");
    }

    // Update the URL with new query params
    navigate({
      pathname: "/providers-map",
      search: params.toString(),
    });
  }, [searchName, searchType, searchCity, searchCountry, navigate]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Providers Map</div>
        </div>
        {/*  */}
        <div className="flex flex-row  justify-between  items-center my-3">
          <div className="mx-1 font-bold text-black ">Providers Map</div>

          <div className="flex flex-row items-center justify-end">
            <div className="mx-2 ">
              <button
                onClick={() => {
                  setIsMaps(!isMaps);
                }}
                className=" rounded px-3 py-1 bg-white shadow-1 border-[#0388A6] text-[#0388A6] hover:bg-[#0388A6] hover:text-white"
              >
                {isMaps ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z"
                    />
                  </svg>
                )}
              </button>
            </div>
            <a
              href="/providers-map/new-provider"
              className="mx-2 flex flex-row bg-[#0388A6] text-white text-xs rounded-full px-5 py-3 items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-4 mx-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <div>New Provider</div>
            </a>
          </div>
        </div>
        <div className="flex md:flex-row flex-col my-2">
          <div className="flex flex-row items-center md:my-0 my-1">
            <div className="mx-1 ">
              <input
                className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                type="text"
                placeholder="Search by Name .."
                value={searchName}
                onChange={(v) => {
                  setSearchName(v.target.value);
                  dispatch(
                    providersList("0", v.target.value, searchType, searchCity)
                  );
                }}
              />
            </div>
            <div className="mx-1 ">
              <select
                onChange={(v) => {
                  setSearchType(v.target.value);
                  dispatch(
                    providersList("0", searchName, v.target.value, searchCity)
                  );
                }}
                className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
              >
                <option value={""}>Select an Type</option>
                {SERVICETYPE?.map((item, index) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-row items-center md:my-0 my-1">
            <div className="mx-1  ">
              <input
                className=" outline-none border border-[#F1F3FF] px-3 py-2 w-full rounded text-sm"
                type="text"
                placeholder="Search by City .."
                value={searchCity}
                onChange={(v) => {
                  setSearchCity(v.target.value);
                  dispatch(
                    providersList("0", searchName, searchType, v.target.value)
                  );
                }}
              />
            </div>
            <div className="mx-1 ">
              <Select
                value={searchCountrySelect}
                onChange={(option) => {
                  setSearchCountry(option.value);
                  setSearchCountrySelect(option);
                }}
                className="outline-none border border-[#F1F3FF] min-w-3  w-full rounded text-sm z-99999"
                options={COUNTRIES.map((country) => ({
                  value: country.title,
                  label: (
                    <div
                      className={`${
                        country.title === "" ? "" : ""
                      } flex flex-row items-center`}
                    >
                      <span className="mr-2">{country.icon}</span>
                      <span>{country.title}</span>
                    </div>
                  ),
                }))}
                placeholder="Select a country..."
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: "#fff",
                    border: "1px solid #F1F3FF",
                    boxShadow: state.isFocused ? "none" : "none",
                    "&:hover": {
                      border: "1px solid #F1F3FF",
                    },
                    minWidth: "10rem",
                  }),
                  option: (base) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                  }),
                }}
              />
            </div>
            <div className="mx-1">
              <button
                onClick={() => {
                  setSearchCity("");
                  setSearchName("");
                  setSearchCountry("");
                  setSearchCountrySelect("");
                  setSearchType("");
                }}
                className="flex flex-row items-center bg-danger text-white px-3 py-1 text-sm rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-4 mx-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
                <div> Reset</div>
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default ">
          <div className=" mx-auto flex flex-col">
            {isMaps ? (
              <div className=" relative">
                <MapContainer
                  center={[0, 0]}
                  zoom={2}
                  style={{ height: "500px", width: "100%" }}
                  className=""
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {providers
                    ?.filter(
                      (provider) => provider.location_x && provider.location_y
                    )
                    .map((provider, index) => (
                      <Marker
                        eventHandlers={{
                          click: () => {
                            setIsOpenMap(true);
                            setProviderMapSelect(provider);
                          }, // Trigger onClick event
                        }}
                        key={index}
                        position={[provider.location_x, provider.location_y]}
                      >
                        <Popup>
                          {provider.full_name}
                          <br />
                        </Popup>
                      </Marker>
                    ))}
                </MapContainer>
                {isOpenMap ? (
                  <div className=" absolute top-0 left-0 z-99999  p-2 md:w-1/3 w-2/3 h-full ">
                    <div className="bg-white shadow-1 w-full h-full">
                      <div className=" p-3 float-right ">
                        <button
                          onClick={() => {
                            setIsOpenMap(false);
                            setProviderMapSelect(null);
                          }}
                          className="rounded-full p-1 bg-danger shadow-1 text-white flex items-center w-max "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18 18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="pt-10 py-4 px-3">
                        {providerMapSelect && (
                          <div>
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.service_type ?? "---"}
                                {providerMapSelect.service_type ===
                                  "Specialists" &&
                                providerMapSelect.service_specialist
                                  ? " : " + providerMapSelect.service_specialist
                                  : ""}
                              </div>
                            </div>
                            {/*  */}

                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.full_name ?? "---"}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.email ?? "---"}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.phone ?? "---"}
                              </div>
                            </div>
                            {/*  */}
                            <div className="flex flex-row items-center text-xs my-3">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                  />
                                </svg>
                              </div>
                              <div className="flex-1 px-2">
                                {providerMapSelect.address ?? "---"}
                              </div>
                            </div>
                            <p className="text-black  text-xs w-max flex flex-row my-4 ">
                              <Link
                                className="mx-1 update-class "
                                to={
                                  "/providers-map/edit/" + providerMapSelect.id
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="w-8 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                  />
                                </svg>
                              </Link>
                              <div
                                onClick={() => {
                                  setEventType("delete");
                                  setProviderId(providerMapSelect.id);
                                  setIsDelete(true);
                                }}
                                className="mx-1 delete-class cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-8 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                  />
                                </svg>
                              </div>
                              <Link
                                className="mx-1 profile-class"
                                to={
                                  "/providers-map/profile/" +
                                  providerMapSelect.id
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5 bg-success rounded p-1 text-white text-center text-xs"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                  />
                                </svg>
                              </Link>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              // <iframe
              //   title="Providers Map"
              //   src="https://www.google.com/maps/d/u/0/embed?mid=1KH5CWcxgH2OO_t1rr6OqMCS-pCVTaik&ehbc=2E312F"
              //   className="min-h-[500px] w-full"
              // ></iframe>
              <div>
                {loadingProviders ? (
                  <Loader />
                ) : errorProviders ? (
                  <Alert type="error" message={errorProviders} />
                ) : (
                  <div className="max-w-full overflow-x-auto ">
                    <table className="w-full table-auto">
                      <thead>
                        <tr className=" bg-[#F3F5FB] text-left ">
                          <th className="min-w-[30px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            #
                          </th>
                          <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                            Provider
                          </th>
                          <th className="min-w-[60px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max ">
                            Type
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Address
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Country
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            City
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Email
                          </th>
                          <th className="min-w-[120px] py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Phone
                          </th>
                          <th className="py-4 px-4 font-bold text-[#8E8E8E] text-xs w-max">
                            Operation
                          </th>
                        </tr>
                      </thead>
                      {/*  */}
                      <tbody>
                        {providers?.map((item, index) => (
                          <tr key={index}>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.id}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <a href={"/providers-map/profile/" + item.id}>
                                <p className="text-black  text-xs w-max  ">
                                  {item.full_name}
                                </p>
                              </a>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.service_type}
                                {item.service_type === "Specialists" &&
                                item.service_specialist
                                  ? " : " + item.service_specialist
                                  : ""}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.address}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.country ?? "----"}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.city ?? "----"}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.email ?? "----"}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max  ">
                                {item.phone ?? "----"}
                              </p>
                            </td>
                            <td className=" py-3 px-4 min-w-[120px]  ">
                              <p className="text-black  text-xs w-max flex flex-row  ">
                                <Link
                                  className="mx-1 update-class"
                                  to={"/providers-map/edit/" + item.id}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                </Link>
                                <div
                                  onClick={() => {
                                    setEventType("delete");
                                    setProviderId(item.id);
                                    setIsDelete(true);
                                  }}
                                  className="mx-1 delete-class cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                    />
                                  </svg>
                                </div>
                                <Link
                                  className="mx-1 profile-class"
                                  to={"/providers-map/profile/" + item.id}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-success rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                    />
                                  </svg>
                                </Link>
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="my-5"></div>
        </div>
        <ConfirmationModal
          isOpen={isDelete}
          message={
            eventType === "delete"
              ? "Are you sure you want to delete this Provider?"
              : "Are you sure ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else if (eventType === "delete" && providerId !== "") {
              setLoadEvent(true);
              dispatch(deleteProvider(providerId));
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setIsDelete(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsDelete(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default ProvidersMapScreen;
