import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import logoMini from "./../images/icon/tassyer-logo-min.png";
import dashboardIcon from "./../images/icon/dashboard-icon.png";
import contactSupportIcon from "./../images/icon/contactsupport-icon.png";
import faqtIcon from "./../images/icon/faqIcon.png";
import helpIcon from "./../images/icon/helpIcon.png";
import casesIcon from "./../images/icon/cases-icon.png";
import logoProjet from "./../images/logo-project.png";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Sidebar = ({ props, sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const [openParametrs, setOpenParametrs] = useState(false);
  const [openDepenses, setOpenDepenses] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;

  const [codeSearch, setCodeSearch] = useState("");

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      console.log(userInfo);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (pathname.includes("/settings")) {
      setOpenParametrs(true);
    }
    if (pathname.includes("/depenses")) {
      setOpenDepenses(true);
    }
  }, [pathname]);

  return (
    <aside
      className={`absolute left-0 top-0 z-999999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-[#f9fafa] shadow duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
        <NavLink to="/dashboard" className="w-full">
          <img
            src={logoProjet}
            cl
            alt="Logo"
            className="text-white mx-auto max-h-25"
          />
        </NavLink>

        <button
          // ref={trigger}
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block lg:hidden text-black"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-3 py-4 px-4 lg:mt-9 lg:px-6">
          {/* <!-- Menu Group --> */}
          <div>
            {/*  */}
            <ul className="mb-6 flex flex-col gap-1.5">
              {/* Tableau de bord */}

              <li>
                <NavLink
                  to="/dashboard"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("dashboard") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img className="size-6 text-danger" src={dashboardIcon} />
                  Dashboard
                </NavLink>
              </li>
              {/* Cases List */}
              <li>
                <NavLink
                  to="/cases-list"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("cases-list") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img className="size-6 text-danger" src={casesIcon} />
                  Cases List
                </NavLink>
              </li>
              {/* Providers Map */}
              <li>
                <NavLink
                  to="/providers-map"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("providers-map") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img className="size-6 text-danger" src={dashboardIcon} />
                  Providers Map
                </NavLink>
              </li>
              {/* Insurance Company */}
              {userInfo &&
              (userInfo.role === "1" ||
                userInfo.role === 1 ||
                userInfo.role === "2" ||
                userInfo.role === 2) ? (
                <li>
                  <NavLink
                    to="/insurances-company"
                    className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                      pathname.includes("insurances-company") &&
                      "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                    }`}
                  >
                    <img className="size-6 text-danger" src={dashboardIcon} />
                    Insurance Company
                  </NavLink>
                </li>
              ) : null}
              {/* Coordinator Space */}
              {userInfo &&
              (userInfo.role === "1" ||
                userInfo.role === 1 ||
                userInfo.role === "2" ||
                userInfo.role === 2) ? (
                <li>
                  <NavLink
                    to="/coordinator-space"
                    className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                      pathname.includes("coordinator-space") &&
                      "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                    }`}
                  >
                    <img className="size-6 text-danger" src={dashboardIcon} />
                    Coordinator Space
                  </NavLink>
                </li>
              ) : null}

              {/* Settings */}
              <li>
                <NavLink
                  // to="/settings"
                  to="/settings"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("settings") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img className="size-6 text-danger" src={dashboardIcon} />
                  Settings
                </NavLink>
              </li>
              {/* Help */}
              {/* <li>
                <NavLink
                  to="/help"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("help") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img className="size-6 text-danger" src={helpIcon} />
                  Help
                </NavLink>
              </li> */}
              {/* FAQ */}
              {/* <li>
                <NavLink
                  to="/faq"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("faq") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img className="size-6 text-danger" src={faqtIcon} />
                  FAQ
                </NavLink>
              </li> */}
              {/* Contact Support */}
              <li>
                <NavLink
                  to="/contact-support"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("contact-support") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <img
                    className="size-6 text-danger"
                    src={contactSupportIcon}
                  />
                  Contact Support
                </NavLink>
              </li>

              <hr />
              {/* Déconnexion */}
              <li>
                <NavLink
                  to="/logout"
                  className={`group relative flex items-center gap-2.5 text-sm py-3 px-4 font-bold text-black h-12  hover:bg-[#f7f9fc] hover:border hover:border-[#7FD082] hover:rounded-md  ${
                    pathname.includes("logout") &&
                    "bg-[#f7f9fc] border border-[#7FD082] rounded-md "
                  }`}
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1946 6.34277C21.3186 9.46677 21.3186 14.5328 18.1946 17.6568C15.0706 20.7808 10.0046 20.7808 6.8806 17.6568C3.7566 14.5328 3.7566 9.46677 6.8806 6.34277"
                      stroke="#DB3C3F"
                      stroke-width="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.5376 4V12"
                      stroke="#DB3C3F"
                      stroke-width="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Log Out
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
