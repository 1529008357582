import { toast } from "react-toastify";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  //
  USER_ADD_SUCCESS,
  USER_ADD_REQUEST,
  USER_ADD_FAIL,
  //
  USER_LIST_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_FAIL,
  //
  USER_PROFILE_SUCCESS,
  USER_PROFILE_REQUEST,
  USER_PROFILE_FAIL,
  //
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_FAIL,
  //
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_FAIL,
  //
  USER_DELETE_SUCCESS,
  USER_DELETE_REQUEST,
  USER_DELETE_FAIL,
  //
  COORDINATOR_LIST_SUCCESS,
  COORDINATOR_LIST_REQUEST,
  COORDINATOR_LIST_FAIL,
  //
  COORDINATOR_ADD_SUCCESS,
  COORDINATOR_ADD_REQUEST,
  COORDINATOR_ADD_FAIL,
  //
  COORDINATOR_DETAIL_SUCCESS,
  COORDINATOR_DETAIL_REQUEST,
  COORDINATOR_DETAIL_FAIL,
  //
  COORDINATOR_UPDATE_SUCCESS,
  COORDINATOR_UPDATE_REQUEST,
  COORDINATOR_UPDATE_FAIL,
  //
  USER_UPDATE_LOGIN_SUCCESS,
  USER_UPDATE_LOGIN_REQUEST,
  USER_UPDATE_LOGIN_FAIL,
  //
  USER_HISTORY_LOGED_SUCCESS,
  USER_HISTORY_LOGED_REQUEST,
  USER_HISTORY_LOGED_FAIL,
  //
  USER_HISTORY_SUCCESS,
  USER_HISTORY_REQUEST,
  USER_HISTORY_FAIL,
  //
  USER_LOGOUT_SAVE_SUCCESS,
  USER_LOGOUT_SAVE_REQUEST,
  USER_LOGOUT_SAVE_FAIL,
  //
} from "../constants/userConstants";

export const historyListCoordinatorReducer = (
  state = { historyCoordinator: [] },
  action
) => {
  switch (action.type) {
    case USER_HISTORY_REQUEST:
      return { loadingHistoryCoordinator: true, historyCoordinator: [] };
    case USER_HISTORY_SUCCESS:
      return {
        loadingHistoryCoordinator: false,
        historyCoordinator: action.payload.historys,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_HISTORY_FAIL:
      return {
        loadingHistoryCoordinator: false,
        errorHistoryCoordinator: action.payload,
      };
    default:
      return state;
  }
};

export const historyListLoggedReducer = (
  state = { historyLogged: [] },
  action
) => {
  switch (action.type) {
    case USER_HISTORY_LOGED_REQUEST:
      return { loadingHistoryLogged: true, historyLogged: [] };
    case USER_HISTORY_LOGED_SUCCESS:
      return {
        loadingHistoryLogged: false,
        historyLogged: action.payload.historys,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_HISTORY_LOGED_FAIL:
      return {
        loadingHistoryLogged: false,
        errorHistoryLogged: action.payload,
      };
    default:
      return state;
  }
};

export const updateLastLoginUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_LOGIN_REQUEST:
      return { loadingUpdateLastLogin: true };
    case USER_UPDATE_LOGIN_SUCCESS:
      return {
        loadingUpdateLastLogin: false,
        successUpdateLastLogin: true,
      };
    case USER_UPDATE_LOGIN_FAIL:
      return {
        loadingUpdateLastLogin: false,
        successUpdateLastLogin: false,
        errorUpdateLastLogin: action.payload,
      };
    default:
      return state;
  }
};

export const updateCoordinatorReducer = (state = {}, action) => {
  switch (action.type) {
    case COORDINATOR_UPDATE_REQUEST:
      return { loadingCoordinatorUpdate: true };
    case COORDINATOR_UPDATE_SUCCESS:
      toast.success("This Coordinator has been updated successfully.");
      return {
        loadingCoordinatorUpdate: false,
        successCoordinatorUpdate: true,
      };
    case COORDINATOR_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingCoordinatorUpdate: false,
        successCoordinatorUpdate: false,
        errorCoordinatorUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailCoordinatorReducer = (
  state = { coordinatorInfo: {} },
  action
) => {
  switch (action.type) {
    case COORDINATOR_DETAIL_REQUEST:
      return { loadingCoordinatorInfo: true };
    case COORDINATOR_DETAIL_SUCCESS:
      return {
        loadingCoordinatorInfo: false,
        successCoordinatorInfo: true,
        coordinatorInfo: action.payload.coordinator,
      };
    case COORDINATOR_DETAIL_FAIL:
      return {
        loadingCoordinatorInfo: false,
        successCoordinatorInfo: false,
        errorCoordinatorInfo: action.payload,
      };
    default:
      return state;
  }
};

export const updatePasswordUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_UPDATE_REQUEST:
      return { loadingUserPasswordUpdate: true };
    case USER_PASSWORD_UPDATE_SUCCESS:
      toast.success("Your password has been successfully updated");
      return {
        loadingUserPasswordUpdate: false,
        successUserPasswordUpdate: true,
      };
    case USER_PASSWORD_UPDATE_FAIL:
      return {
        loadingUserPasswordUpdate: false,
        errorUserPasswordUpdate: action.payload,
        successUserPasswordUpdate: false,
      };
    default:
      return state;
  }
};

export const createCoordinatorReducer = (state = {}, action) => {
  switch (action.type) {
    case COORDINATOR_ADD_REQUEST:
      return { loadingCoordinatorAdd: true };
    case COORDINATOR_ADD_SUCCESS:
      toast.success("This Coordinator has been added successfully");
      return {
        loadingCoordinatorAdd: false,
        successCoordinatorAdd: true,
      };
    case COORDINATOR_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingCoordinatorAdd: false,
        successCoordinatorAdd: false,
        errorCoordinatorAdd: action.payload,
      };
    default:
      return state;
  }
};

export const coordinatorsListReducer = (
  state = { coordinators: [] },
  action
) => {
  switch (action.type) {
    case COORDINATOR_LIST_REQUEST:
      return { loadingCoordinators: true, coordinators: [] };
    case COORDINATOR_LIST_SUCCESS:
      return {
        loadingCoordinators: false,
        coordinators: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case COORDINATOR_LIST_FAIL:
      return { loadingCoordinators: false, errorCoordinators: action.payload };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loadingUserDelete: true };
    case USER_DELETE_SUCCESS:
      toast.success("This Coordinator has been successfully deleted.");
      return {
        loadingUserDelete: false,
        successUserDelete: true,
      };
    case USER_DELETE_FAIL:
      return {
        loadingUserDelete: false,
        errorUsersDelete: action.payload,
        successUserDelete: false,
      };
    default:
      return state;
  }
};

export const updateProfileUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { loadingUserProfileUpdate: true };
    case USER_PROFILE_UPDATE_SUCCESS:
      toast.success("Your profile has been successfully updated");
      return {
        loadingUserProfileUpdate: false,
        successUserProfileUpdate: true,
      };
    case USER_PROFILE_UPDATE_FAIL:
      return {
        loadingUserProfileUpdate: false,
        errorUserProfileUpdate: action.payload,
        successUserProfileUpdate: false,
      };
    default:
      return state;
  }
};

export const getProfileUserReducer = (state = { userProfile: [] }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { loadingUserProfile: true };
    case USER_PROFILE_SUCCESS:
      return {
        loadingUserProfile: false,
        userProfile: action.payload.profile,
        successUserProfile: true,
      };
    case USER_PROFILE_FAIL:
      return {
        loadingUserProfile: false,
        errorUserProfile: action.payload,
        successUserProfile: false,
      };
    default:
      return state;
  }
};

export const createNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ADD_REQUEST:
      return { loadingUserAdd: true };
    case USER_ADD_SUCCESS:
      toast.success("This user has been added successfully");
      return {
        loadingUserAdd: false,
        successUserAdd: true,
      };
    case USER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingUserAdd: false,
        successUserAdd: false,
        errorUserAdd: action.payload,
      };
    default:
      return state;
  }
};

export const usersListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loadingUsers: true, users: [] };
    case USER_LIST_SUCCESS:
      return {
        loadingUsers: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case USER_LIST_FAIL:
      return { loadingUsers: false, errorUsers: action.payload };
    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const logoutSavedUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT_SAVE_REQUEST:
      return { loadingUserLogout: true };
    case USER_LOGOUT_SAVE_SUCCESS:
      toast.success("You are has been logout successfully");
      return {
        loadingUserLogout: false,
        successUserLogout: true,
      };
    case USER_LOGOUT_SAVE_FAIL:
      toast.error(action.payload);
      return {
        loadingUserLogout: false,
        successUserLogout: false,
        errorUserLogout: action.payload,
      };
    default:
      return state;
  }
};
